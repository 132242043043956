//Customizable Area Start
import React, { Component } from "react";
import { MenuItem, Divider, Typography } from "@material-ui/core";
import "./VideoChatScreen.css";

interface MyProps {
  color: string;
  value: string;
  icon: any;
  handlerFunction: (s: string) => void;
  param: string | null;
  lastOne?: boolean;
}

export default class MenuList extends Component<MyProps> {
  render() {
    const { color, value, icon, handlerFunction, param, lastOne } = this.props;

    return (
      <>
        <MenuItem
          style={{ color }}
          onClick={() => handlerFunction(param || "")}
        >
          <Typography component='span' className="menulist-icon">{icon}</Typography>
          <Typography component='span' style={{ whiteSpace: "initial", fontWeight: 500 }}>
            {value}
          </Typography>
        </MenuItem>
        {lastOne ? null : <Divider />}
      </>
    );
  }
}
// Customizable Area End
