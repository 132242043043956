// Customizable Area Start
import { IBlock } from '../../../../framework/src/IBlock';
import { Message } from '../../../../framework/src/Message';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import Video from 'twilio-video';
import { Client } from '@twilio/conversations';
const x = require('../../../../framework/src/config.js');
export const configJSON = require("./config.js");
import actionCable from 'actioncable';
import { RouteComponentProps } from "react-router-dom";

let firstRender: boolean;
export interface Props extends RouteComponentProps {
    // navigation: any;
    // id: string;
}

interface S {
    accessToken: string,
    room: any,
    client: any,
    statusString: string,
    isConnected: boolean,
    errorAlert: boolean,
    customErrorAlert: any,

    remoteIdentityList: any,
    localIdentity: any

    allRoomParticipants: any;
    startTime: string,
    endTime: string,
    roomTopic: string,
    roomPrice: number,
    myAccountId: number,
    myWalletBalance: number,

    roomSID: any;
    conversationSID: any;
    isModerated: boolean;
    room_type: string;

    feedbackData: any;
    joined_users: string[];
    on_call_users: string[];
    roomDisconnected: boolean;

    hostId: number | null;
    isStandrardRoom: boolean;
    isHostJoined: boolean;
    roomDescription: string;
    roomImgLink: string;
    isDescriptionOpen: boolean;
    isMobile: boolean;

    roomId: (string | number);
    snackbar: { type: string, message: string, open: boolean, duration: number };
    selectedID: number | null;

    currentUnixTime: number | null;
}

interface SS {
    id: any;
}

export default class RoomController extends BlockComponent<Props, S, SS> {
    accessTokenMessageId: string
    cable: actionCable.Cable
    roomDetailMessageId: string;
    verifyCurrentTime: string;
    createTwilioRoomMessageId: string;
    roomExtensionMessageId: string;
    sendConnectionMessageId: string;
    revertConnectionRequestID: string;
    disconnectUserID: string;
    subscription: actionCable.Channel & {
        connected: () => void;
        disconnected: () => void;
        received: (data: any) => void;
    };
    blockSubscription: actionCable.Channel & {
        connected: () => void;
        disconnected: () => void;
        received: (data: any) => void;
    };

    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        firstRender = true;

        this.state = {
            accessToken: "",
            room: null,
            client: null,
            statusString: "Connecting ...",
            isConnected: false,
            errorAlert: false,
            customErrorAlert: null,

            remoteIdentityList: [],
            localIdentity: null,

            allRoomParticipants: [],
            startTime: "",
            endTime: "",
            roomTopic: "",
            roomPrice: 0,
            myAccountId: -1,
            myWalletBalance: 0,

            roomSID: null,
            conversationSID: null,
            isModerated: false,
            room_type: "",

            feedbackData: new Map(),
            joined_users: [],
            on_call_users: [],
            roomDisconnected: false,
            hostId: null,
            isStandrardRoom: false,
            isHostJoined: false,
            roomDescription: '',
            roomImgLink: '',
            isDescriptionOpen: false,
            isMobile: false,

            roomId: this.props.location.pathname.split('/')[2],
            snackbar: { type: "", message: "", open: false, duration: 5000 },
            selectedID: null,
            currentUnixTime: null,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // handle Joined users
    handleJoinedUsers = (joined_users: string) => {
        const users: string[] = joined_users?.split(",");
        this.setState({ joined_users: [...users] }, () => {
            // check for host joining
            const { hostId, isStandrardRoom } = this.state;
            // no waiting room for standard room
            if (isStandrardRoom) return;
            if (!hostId) return;
            const currentUserId = JSON.parse(localStorage.getItem("userId") as string);
            // if user is a host
            if (currentUserId === hostId) return;
            // because first the host will Join the call.
            if (users.length === 1) {
                // reload page
                window.location.reload();
            }
        });
    }

    // handle connection status
    handleConnectionStatus = (data: any) => {
        let searchKeys: number[] = [];
        if (data?.name === 'Disconnected User') {
            searchKeys.push(data?.account_id);
        } else if (data?.status === 'Connected' || data?.status === 'Connect') {
            searchKeys.push(data?.account_id, data?.sender_id);
        } else {
            searchKeys.push(data?.sender_id);
        }

        return searchKeys;
    }

    initilizeSocket = () => {
        const cable = actionCable.createConsumer(
            `${x.baseURL}/cable?token=${localStorage.getItem('token')}`
        );
        // AccountsChannel
        this.subscription = cable.subscriptions.create(
            {
                channel: "AccountsChannel",
                account_id: localStorage.getItem("userId")
            },
            {
                connected: () => { },
                disconnected: () => { },
                received: (data: any) => {
                    if (data?.room_update) {
                        this.setState({ customErrorAlert: data?.room_update });
                    }

                    if (data?.on_call_users) {
                        this.setState({ on_call_users: data?.on_call_users.split(",") });
                    }

                    if (data?.joined_users?.length) {
                        this.handleJoinedUsers(data?.joined_users);
                    }

                    const senderID = data?.request?.sender_id;
                    // if someone is not in the room and send a request
                    if (senderID) {
                        if (!this.state.joined_users.includes(senderID.toString())) {
                            return;
                        }
                    }

                    const searchKeys = this.handleConnectionStatus(data);
                    const tempParticipants = [...this.state.allRoomParticipants];

                    searchKeys.forEach((searchKey) => {
                        const participant = tempParticipants.find((each) => each.account_id === searchKey);
                        if (participant) {
                            participant.connection_status = data?.status === "Connect" ? "Reject" : data?.status;
                        }
                        this.setState({ allRoomParticipants: tempParticipants });
                    });

                    if (data?.request?.account_id) {
                        const newParticipants = tempParticipants.map((each) => each.account_id === data?.request?.sender_id ?
                            { ...each, connection_status: { ...data?.request, status: data.status } } : each);

                        this.setState({ allRoomParticipants: newParticipants });
                    }
                }
            }
        );

        // BlockUsersChannel
        this.blockSubscription = cable.subscriptions.create(
            {
                channel: "BlockUsersChannel",
                account_id: localStorage.getItem("userId"), // userID menas current user id
            },
            {
                connected: () => { 
                    console.log("BlockUsersChannel connected");
                },
                disconnected: () => { 
                    console.log("BlockUsersChannel disconnected");
                },
                received: (data: any) => {
                    console.log("BlockUsersChannel received", data)
                    const userID = data?.user_id?.toString();
                    const currentUserID = localStorage.getItem("userId");

                    let status = '';
                    let account_id = '';

                    if (userID === currentUserID) {
                        status = 'BlockedBy';
                        account_id = data?.message?.account_id;
                    } else {
                        status = 'Blocked';
                        account_id = data?.message?.blocked_account_id;
                    }

                    // find and update status
                    const tempParticipants = [...this.state.allRoomParticipants];
                    const participant = tempParticipants.find((each) => each.account_id === account_id);
                    if (participant) {
                        participant.connection_status = data?.type === "user_blocked" ? status : 'connect';
                    }

                    this.setState({ allRoomParticipants: tempParticipants });
                },
            }
        )
    }

    forceUpdateParticipantsStatus = () => {
        const data = JSON.parse(sessionStorage.getItem("update_friend_request_status") as string);

        if (!data?.user_id) return;

        const tempParticipants = [...this.state.allRoomParticipants];
        const newParticipants = tempParticipants.map((each) => each.account_id === data?.user_id ?
            { ...each, connection_status: { ...each.connection_status, status: "Pending" } } : each);

        this.setState({ allRoomParticipants: newParticipants });
    }

    openSnackbar = (message: string, type: string) => {
        this.setState(prev => ({ snackbar: { ...prev.snackbar, open: true, message, type } }), () => {
            setTimeout(() => {
                this.closeSnackbar();
            }, this.state.snackbar.duration);
        });
    }

    closeSnackbar = () => {
        this.setState(prev => ({ snackbar: { ...prev.snackbar, open: false } }));
    }

    async componentDidMount() {
        // if no token is there
        if (!localStorage.getItem("token")) {
            this.props.history.push("registration")
        }
        this.initilizeSocket();

        const userId = parseInt(localStorage.getItem("userId") ?? "-1");
        const roomId = this.props.location.pathname.split('/')[2];
        this.setState({ myAccountId: userId, roomId }, () => {
            this.getCurrentTime();
        });

        // mobile check
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
        // add storage listener to update friend request status 
        window.addEventListener("storage", this.forceUpdateParticipantsStatus, false);
    }

    createFeedbackUsersArray = () => {
        let temp = this.state.feedbackData;
        const currentUserID = localStorage.getItem("userId");
        this.state.allRoomParticipants.map((participant: any, i: number) => {
            if (Number(currentUserID) !== Number(participant.account_id)) {
                if (!this.state.feedbackData.get(participant.account)) {
                    //if doesn't contain previously create new participant
                    temp.set(participant?.account_id, {
                        userName: participant.user_name,
                        full_name: participant.full_name,
                        profileImage: participant.profile_img,
                        userId: participant?.account_id,
                        isHost: i === 0,
                        hearts: 0,
                        anonymously_name: participant?.anonymously_name || '',
                        is_anonymously: participant?.is_anonymously || false
                    });
                }
            }
        });
        this.setState({ feedbackData: temp });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        // once accesstoken updates from "" to something call join room
        if (prevState.accessToken !== this.state.accessToken && !this.state.errorAlert) {
            this.joinRoom(this.state.accessToken);
            this.initializedClient(this.state.accessToken);
        }
        if (prevState.remoteIdentityList !== this.state.remoteIdentityList || prevState.allRoomParticipants !== this.state.allRoomParticipants) {
            //above runs for (someone already registered in room joined || someone who newly registered in room joined )
            this.createFeedbackUsersArray();
        }
    }

    async componentWillUnmount() {
        const { room } = this.state;

        if (room) {
            this.subscription.perform('receive', {
                "msg_type": "user_left_call",
                "room_id": this.state.roomId.toString(),
                "user_id": localStorage.getItem("userId")
            });

            await room.disconnect();
            this.setState({ room: null });
        }

        window.removeEventListener("resize", this.handleResize);
        // remove storage listener
        window.removeEventListener("storage", this.forceUpdateParticipantsStatus, false);
    };

    handleSendConnection = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (errorJson) {
            return;
        }

        this.openSnackbar("Connect request sent", "success");
        const account_id = responseJson?.data?.attributes?.account_id;
        if (account_id) {
            const tempParticipants = [...this.state.allRoomParticipants];
            const participant = tempParticipants.find((each) => each.account_id === account_id);
            if (participant) {
                participant.connection_status = responseJson?.data?.attributes?.status;
                this.setState({ allRoomParticipants: tempParticipants });
            }
        }
    }

    handleUnSendConnection = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (errorJson) {
            return;
        }

        this.openSnackbar("Connect request reverted", "success");
        const account_id = responseJson?.data?.account_id;
        if (account_id) {
            const tempParticipants = [...this.state.allRoomParticipants];
            const participant = tempParticipants.find((each) => each.account_id === account_id);
            console.log(participant);
            if (participant) {
                participant.connection_status = responseJson?.data?.status;
                this.setState({ allRoomParticipants: tempParticipants });
            }
        }
    }

    handleDisconnect = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (errorJson) {
            return;
        }

        this.openSnackbar("Disconnect request sent", "success");
        console.log(responseJson);

        const account_id = this.state.selectedID;
        if (account_id) {
            const tempParticipants = [...this.state.allRoomParticipants];
            const participant = tempParticipants.find((each) => each.account_id === account_id);
            if (participant) {
                participant.connection_status = 'connect'; // hardcode because we are not getting status from backend
                this.setState({ allRoomParticipants: tempParticipants });
            }
        }
    }

    handleCreateTwilioRoom = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (responseJson?.errors) {
            this.setState({ errorAlert: true, customErrorAlert: responseJson?.errors[0]?.message });
            return;
        }
        let data = responseJson;
        this.setState({ roomSID: data.room_sid, conversationSID: data.conversation_sid, accessToken: data.video_grant_token })
    }

    handleAccessToken = (apiCallId: any, responseJson: any, errorJson: any) => {
        if(this.accessTokenMessageId === apiCallId) {
            if (responseJson?.errors) {
                this.setState({ errorAlert: true, customErrorAlert: responseJson?.errors[0]?.message });
                return;
            }
            this.setState({ accessToken: responseJson.token });
        }
    }

    handlJoinedUsersForRoomDetails = (responseJson: any) => {
        this.setState({
            hostId: responseJson?.host_details?.account_id,
            isStandrardRoom: responseJson?.room_type === 'Standard',
            roomDescription: responseJson.room_description,
            roomImgLink: responseJson?.description_image,
        });
        const joined_users: string = responseJson?.joined_users || '';

        if (joined_users?.length) {
            const users = joined_users?.split(",");
            this.setState({ joined_users: users });
        }
    }

    handleRoomDetails = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (responseJson?.errors || errorJson) {
            this.setState({ errorAlert: true, customErrorAlert: responseJson?.errors[0] || "Someting went wrong" }, () => {
                // redirect to rooms page
                setTimeout(() => {
                    this.props.history.push('/Rooms');
                }, 1500);
            });

            return;
        }

        this.handlJoinedUsersForRoomDetails(responseJson);

        let userId = parseInt(localStorage.getItem("userId") || "-1");
        let data = responseJson;
        let temp = [{ ...data.host_details, is_host: true }];
        if (userId === data?.host_details?.account_id) {
            this.setState({ myWalletBalance: data.host_details.wallet })
        }

        data?.participant_users.forEach((p: any) => {

            if (this.state.myWalletBalance === 0 && userId === p?.account_id) {
                this.setState({ myWalletBalance: p.wallet })
            }
            temp = [...temp, p]
        })
        let startTime = data.start_time;
        let endTime = data.end_time;
        //@ts-ignore
        if (Date.now() > new Date(endTime)) {
            let err = new Error('Room time has expired')
            throw err;
        }
        this.setState({
            allRoomParticipants: temp,
            startTime,
            endTime,
            roomTopic: data.topic_name,
            roomPrice: data.room_price,
            roomSID: data?.sids?.room_sid,
            conversationSID: data?.sids?.conversation_sid,
            isModerated: data?.room_type === "Moderated",
            room_type: data?.room_type,
        }, () => {
            //for any user(host or participant) and room sid is not there
            if (firstRender) {
                firstRender = false;
                if (!this.state.roomSID) { // if room is not already created
                    this.createTwilioRoom();
                } else {
                    this.getAccessToken();
                }
            }
        });
    }

    handleVerifyCurrentTime = (apiCallId: any, responseJson: any) => {
        if (this.verifyCurrentTime === apiCallId) {
            if (responseJson?.unixtime) {
                this.setState({ currentUnixTime: responseJson.unixtime });
                this.getRoomDetails(responseJson.unixtime);
            } else {
                this.openSnackbar("Something went wrong", "error");
            }
        }
    }

    handleRoomExtension = (apiCallId: any, responseJson: any, errorJson: any) => {
        if (this.roomExtensionMessageId === apiCallId) {
            this.setState({ endTime: responseJson?.data?.attributes?.end_time });
            this.setState({ myWalletBalance: this.state.myWalletBalance - this.state.roomPrice });
        }
    }

    handleError = (error: any) => {
        this.setState({ errorAlert: true, customErrorAlert: error.message }, async () => {
            if (error.message === "Room time has expired") {
                await this.state.room?.disconnect()
                //! uncomment to redirect 
                // setTimeout(()=>this.props.history.push('/Rooms?type=my'),3000)
            }
        })
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog('Message Received', message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (errorJson) {
                this.setState({ errorAlert: true });
                return;
            }

            try {
                if (responseJson?.errors && responseJson?.errors[0]?.token === "Token has Expired") {
                    throw new Error("Token has Expired")
                }
                if (this.sendConnectionMessageId === apiCallId) {
                    this.handleSendConnection(apiCallId, responseJson, errorJson);
                }
                else if (this.revertConnectionRequestID === apiCallId) {
                    this.handleUnSendConnection(apiCallId, responseJson, errorJson);
                }
                else if (this.disconnectUserID === apiCallId) {
                    this.handleDisconnect(apiCallId, responseJson, errorJson);
                }
                else if (this.createTwilioRoomMessageId === apiCallId) {
                    this.handleCreateTwilioRoom(apiCallId, responseJson, errorJson);
                }
                else if (this.roomDetailMessageId === apiCallId) {
                    this.handleRoomDetails(apiCallId, responseJson, errorJson);
                }
                this.handleVerifyCurrentTime(apiCallId, responseJson);
                this.handleRoomExtension(apiCallId, responseJson, errorJson);
                this.handleAccessToken(apiCallId, responseJson, errorJson);
            } catch (error: any) {
                this.handleError(error);
            }
        }
    }

    getAccessToken = () => {
        let reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

        let roomSID = this.state.roomSID;
        reqMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.participantTokenGenerationEndpoint}?room_sid=${roomSID}&room_id=${this.state.roomId}`);
        reqMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);

        reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), { token: localStorage.getItem('token') });

        this.accessTokenMessageId = reqMsg.messageId;
        runEngine.sendMessage(reqMsg.id, reqMsg);
    }

    getRoomDetails = (UTime: number | null = null) => {
        let reqMsg2 = new Message(getName(MessageEnum.RestAPIRequestMessage));

        reqMsg2.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.roomDetailsEndpoint}?room_id=${this.state.roomId}`);
        reqMsg2.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetType);

        reqMsg2.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
            token: localStorage.getItem('token'),
            TZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            UTime: UTime ?? Math.floor(new Date().getTime() / 1000).toString()
        });

        this.roomDetailMessageId = reqMsg2.messageId;
        runEngine.sendMessage(reqMsg2.id, reqMsg2);
    }

    // fetch unix time 
    getCurrentTime = () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.verifyCurrentTime = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/get_time`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    createTwilioRoom = () => {
        let reqMsg3 = new Message(getName(MessageEnum.RestAPIRequestMessage));
        reqMsg3.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
        reqMsg3.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.createTwilioRoomEndpoint);
        const header = {
            token: localStorage.getItem("token")
        };

        reqMsg3.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        let formdata = new FormData();
        formdata.append("room_id", this.state.roomId.toString());
        reqMsg3.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

        this.createTwilioRoomMessageId = reqMsg3.messageId;
        runEngine.sendMessage(reqMsg3.id, reqMsg3);
    }

    sendRoomExtensionRequest = () => {
        let requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostType);
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.roomExtensionEndpoint);
        const header = {
            token: localStorage.getItem("token")
        };

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        let formdata = new FormData();
        formdata.append("room_id", this.state.roomId.toString());
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);

        this.roomExtensionMessageId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    async joinRoom(accessToken: string) {
        try {
            const room = await Video.connect(accessToken, {
                dominantSpeaker: true,
                audio: true,
                video: true,
            });

            room.on("disconnected", (room: any, error: any) => {
                window.setTimeout(() => {
                    this.setState({ statusString: "disconnected", roomDisconnected: true });
                }, 1000);
            });

            this.setState({ room: room });
        } catch (err: any) {
            this.setState({ errorAlert: true, customErrorAlert: err.message })
        }
    }

    initializedClient = (accessToken: string) => {
        const client = new Client(accessToken);
        client.on("initialized", () => {
            this.setState({ statusString: "initialized", isConnected: true, client: client });
        })
        client.on('initFailed', ({ error }) => {
            this.setState({ statusString: "initFailed" });
        });
    }

    handleStateUplifting = (data: any) => {
        let remote: any = [];
        let local;
        data.forEach((x: any, i: number) => {
            if (i === 0) {
                local = x.identity;
            }
            else {
                remote = [...remote, x.identity]
            }
        })
        this.setState({ remoteIdentityList: remote, localIdentity: local });
    }

    handleFeedbackData = (data: any) => {
        this.setState({ feedbackData: data });
    }

    sendConnectionRequest = (ID: number) => {
        const token = localStorage.getItem('token');

        if (!token) return;

        let reqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendConnectionRequestEndpoint
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostType
        );

        const myHeaders = new Headers();

        myHeaders.append('token', token);
        myHeaders.append('Content-Type', 'application/json');
        let raw = JSON.stringify({
            data: {
                account_id: ID
            }
        });
        reqMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), myHeaders);
        reqMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), raw);

        this.sendConnectionMessageId = reqMsg.messageId;
        runEngine.sendMessage(reqMsg.id, reqMsg);
    };

    revertConnectionRequest = (user_id: number) => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("token")
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.revertConnectionRequestID = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_request_management/requests/unsent_request?account_id=${user_id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'PUT'
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    handleDisconnectUser = (user_id: number) => {
        this.setState({ selectedID: user_id });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": localStorage.getItem("token")
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.disconnectUserID = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_request_management/requests/disconnect_request?account_id=${user_id}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'POST'
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    endRoomForAll = () => {
        const token = localStorage.getItem("token");

        if (token) {
            const header = {
                // "Content-Type": configJSON.contentType,
                token: token
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.endCallForAllEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.endCallForCallMethod
            );

            const [_, ID] = window.location.href.split("Room/");
            const formData = new FormData();
            formData.append("room_id", ID);

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            )

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    }

    handleResize = () => {
        const isMobile = window.innerWidth <= 768;
        this.setState({ isMobile: isMobile });
    };

    toggleRoomDescription = () => {
        this.setState(prev => ({ isDescriptionOpen: !prev.isDescriptionOpen }))
    }
}
// Customizable Area End
